import React, { useEffect, useState } from 'react';
import { Document } from 'common/interfaces/document';
import { useAPI } from '@/api/APIContext';

interface ImportalViewDocumentProps {
  document: Document | null; // Allow document to be null initially
  documentData: any | null; // Allow documentData to be null initially
}

const ImportalViewDocument: React.FC<ImportalViewDocumentProps> = ({ document, documentData }) => {
  const api = useAPI();
  const [src, setSrc] = useState<string | null>(null);
  const [fileTypeSupported, setFileTypeSupported] = useState<boolean | null>(null);

  // Supported file types and their MIME types
  const supportedFileTypes = [
    { extension: 'pdf', type: 'application/pdf' },
    { extension: 'xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: 'xls', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  ];

  // Function to determine the file type of the document
  const determineFileType = (document: Document) => {
    const fileExtension = document.fileName?.split('.').pop()?.toLowerCase();
    return supportedFileTypes.find((ft) => ft.extension === fileExtension);
  };

  useEffect(() => {
    const fetchDocument = async () => {
      // Wait for both document and documentData to be available before proceeding
      if (!document || !documentData) {
        return;
      }

      const fileType = determineFileType(document);

      if (fileType) {
        setFileTypeSupported(true);

        switch (fileType.extension) {
          case 'pdf': {
            const blob = new Blob([documentData], { type: fileType.type });
            const url = URL.createObjectURL(blob);
            setSrc(url);
            break;
          }
          case 'xls':
          case 'xlsx': {
            try {
              const documentSignedUrlResponse = await api.getDocumentSignedURL(document._id.toString());
              const signedUrl = documentSignedUrlResponse.data;
              const xlsxUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(signedUrl)}`;
              setSrc(xlsxUrl);
            } catch (error) {
              console.error('Error fetching document URL:', error);
              setFileTypeSupported(false);
            }
            break;
          }
          default:
            setFileTypeSupported(false);
            break;
        }
      } else {
        setFileTypeSupported(false);
      }
    };

    fetchDocument();
  }, [document, documentData, api]);

  // Render the loading message if document or documentData are not yet available
  if (!document || !documentData) {
    return <div className="no-results-container">Loading document information, please wait...</div>;
  }

  // Render document based on file type support
  if (fileTypeSupported === true && src) {
    return <iframe src={src} title="View Document" style={{ width: '100%', minHeight: '600px', height: '100%' }} />;
  }

  if (fileTypeSupported === false) {
    return (
      <div className="no-results-container">
        Boom! Your document should download instantly. If you have any issues,
        <a style={{ marginLeft: '3px', marginRight: '3px' }} href={api.getDocumentURL(document)}>
          click here
        </a>
        to manually download it.
      </div>
    );
  }

  return <div className="no-results-container">Loading document information, please wait...</div>;
};

export default ImportalViewDocument;
