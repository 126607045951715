import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, IconButton, TextField, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './AddOrEditBusinessModal.css';
import { useAPI } from '@/api/APIContext';
import { BusinessAction, BusinessUser, BusinessWithDocuments, ComplianceProfile } from 'common/interfaces/business';
import { AxiosError } from 'axios';
import Checkbox from '@mui/material/Checkbox';
import { validateEmail } from '@/utils/validateEmail';

enum Mode {
  Add,
  Edit,
}

export interface Props {
  open: boolean;
  onClose: (e?: any) => void;
  businessId?: string;
  onBusinessCreatedOrModified: (businessId: string, action: BusinessAction) => void;
}

enum CheckBox {
  YES,
  NO,
}

type ValidationErrors = Array<string>;

export default function AddOrEditBusinessModal({ open, onClose, businessId, onBusinessCreatedOrModified }: Props) {
  const api = useAPI();
  const theme = useTheme();
  const [mode, setMode] = useState<Mode>(businessId ? Mode.Edit : Mode.Add);
  const [isEdited, setIsEdited] = useState(false);
  const [businessUnderEdit, setBusinessUnderEdit] = useState<BusinessWithDocuments | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [isBusinessCustomsOnboarded, setIsBusinessCustomsOnboarded] = useState(false);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>([]);

  const [businessUsers, setBusinessUsers] = useState<Array<BusinessUser>>([]);
  const defaultBusinessUser: BusinessUser = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const [businessUser, setBusinessUser] = useState<BusinessUser>(defaultBusinessUser);

  const createCheckboxHandler = (box: CheckBox): ((e) => void) => {
    return (e) => {
      if (box === CheckBox.YES) {
        setIsBusinessCustomsOnboarded(e.target.checked);
      } else {
        setIsBusinessCustomsOnboarded(!e.target.checked);
      }
    };
  };

  // validate
  const validateBusiness = (): ValidationErrors => {
    const errors: ValidationErrors = [];

    if (businessUnderEdit) {
      const domainNameRegex = new RegExp(
        /^(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])$/i
      );
      businessUnderEdit.businessDomains?.forEach((domain) => {
        if (!domainNameRegex.test(domain)) {
          errors.push(`The domain name: ${domain} is not valid.`);
        }
      });
    }

    const hasAnyBusinessUserData = !!(businessUser.firstName || businessUser.lastName || businessUser.email);
    if (hasAnyBusinessUserData && !validateEmail(businessUser.email)) {
      errors.push(`email: [${businessUser.email}] is not valid`);
    }

    return errors;
  };

  useEffect(() => {
    setValidationErrors([]);
  }, [businessUnderEdit, businessUser]);

  useEffect(() => {
    if (!businessId) {
      setMode(Mode.Add);
      const business: Omit<BusinessWithDocuments, '_id'> = {
        name: '',
        businessDomains: [],
        documents: [],
        bonds: '',
        bondInfo: '',
        isf: '',
        customsClearance: '',
        classification: '',
        disbursement: '',
        partners: '',
        pointOfContact: '',
        paymentMethodCustoms: '',
        complianceProfile: {} as ComplianceProfile,
      };
      // @ts-ignore
      setBusinessUnderEdit(business);
      setBusinessUsers([]);
    } else {
      setMode(Mode.Edit);
      setIsEdited(false);
      setLoading(true);
      api
        .getBusinessById(businessId)
        .then(({ data: business }) => {
          setBusinessUnderEdit(business);
        })
        .catch((err) => {
          console.error('error getting business for add/edit modal');
          console.error(err);
        });

      api
        .getBusinessUsers(businessId)
        .then(({ data: users }) => {
          setBusinessUsers(users);
        })
        .catch((err) => {
          console.error('error getting users for business');
          console.error(err);
        });
    }
    setLoading(false);
  }, [businessId]);

  const clearModalVariables = () => {
    setBusinessUnderEdit(undefined);
    setBusinessUser(defaultBusinessUser);
    setIsBusinessCustomsOnboarded(false);
  };

  const onSubmit = async () => {
    const errors = validateBusiness();
    if (errors && errors.length) {
      setValidationErrors(errors);
      return;
    }

    setLoading(true);
    if (mode === Mode.Add) {
      try {
        api
          .createBusinessViaBroker({
            business: businessUnderEdit!,
            user: businessUser,
            customsOnboarded: isBusinessCustomsOnboarded,
          })
          .then((response) => {
            const business = response.data;
            clearModalVariables();
            onBusinessCreatedOrModified(business._id.toString(), BusinessAction.CREATED);
          })
          .catch((error) => {
            // Handle the error if needed
            console.error('Error creating business:', error);
          });

        onClose();
      } catch (err) {
        console.error('error creating business');
        console.error(err);
        if ((err as AxiosError).response?.data) {
          //@ts-ignore
          const { errors } = err.response.data;
          setValidationErrors(errors);
        }
      }
    } else if (mode === Mode.Edit) {
      try {
        // update businessUsers first
        const { data: newBusinessUsers } = await api.updateBusinessUsers(businessId!, businessUsers);
        setBusinessUsers(newBusinessUsers);

        await api.updateBusiness(businessId!, businessUnderEdit!);
        onBusinessCreatedOrModified(businessId!, BusinessAction.MODIFIED);
        onClose();
      } catch (err) {
        console.error('error updating business');
        console.error(err);
        if ((err as AxiosError).response?.data) {
          //@ts-ignore
          const { errors } = err.response.data;
          setValidationErrors(errors);
        }
      }
    }
    setLoading(false);
  };

  const handleChange = (field: keyof BusinessWithDocuments) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    setBusinessUnderEdit({ ...businessUnderEdit!, [field]: event.target.value });
  };

  const handleChangeBusinessUser = (key, value) => {
    setIsEdited(true);
    setBusinessUser((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Dialog maxWidth="md" fullWidth={true} onClose={onClose} open={open}>
      <div className="header-question">
        <Typography>{Mode[mode]} Business</Typography>
      </div>
      <div className="business-subtitle-container">
        <div>{Mode[mode]} the business name and initial point of contact information</div>
      </div>
      <IconButton
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey',
        }}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>

      <form id={'business-properties-form'}>
        {validationErrors?.length > 0 && (
          <ul style={{ color: 'red' }}>
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
        <div className="add-business-description-title">Business Information</div>
        <div style={{ padding: '12px' }} className="name-row">
          <div className="signup-form-field-container">
            <div className="business-form-field-label">
              <Typography sx={{ fontWeight: '600' }}>Name</Typography>
            </div>
            <div>
              <TextField
                autoComplete="given-name"
                name="firstName"
                value={businessUnderEdit?.name || ''}
                onChange={handleChange('name')}
                fullWidth
                id="firstName"
                autoFocus
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="customs-onboarded-title-container">
          <div className="title-question-header-container">
            <div className="title-question-header">Are they customs onboarded?</div>
          </div>
          <div className="title-question-body">
            <div className="checkbox-container-title">
              Yes
              <Checkbox checked={isBusinessCustomsOnboarded} onChange={createCheckboxHandler(CheckBox.YES)} />
              No
              <Checkbox checked={!isBusinessCustomsOnboarded} onChange={createCheckboxHandler(CheckBox.NO)} />
            </div>
          </div>
        </div>
        <div className="add-business-description-title">Point of Contact</div>
        <div style={{ padding: '12px' }} className="name-row">
          <div className="signup-form-field-container">
            <div className="business-form-field-label">
              <Typography sx={{ fontWeight: '600' }}>First Name</Typography>
            </div>
            <div>
              <TextField
                autoComplete="given-name"
                name="firstName"
                value={businessUser.firstName}
                onChange={(e) => handleChangeBusinessUser('firstName', e.target.value)}
                fullWidth
                id="firstName"
                autoFocus
                size="small"
              />
            </div>
          </div>
          <div className="signup-form-field-container">
            <div className="business-form-field-label">
              <Typography sx={{ fontWeight: '600' }}>Last Name</Typography>
            </div>
            <div>
              <TextField
                value={businessUser.lastName}
                onChange={(e) => handleChangeBusinessUser('lastName', e.target.value)}
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                size="small"
              />
            </div>
          </div>
          <div className="signup-form-field-container">
            <div className="business-form-field-label">
              <Typography sx={{ fontWeight: '600' }}>Email</Typography>
            </div>
            <div>
              <TextField
                value={businessUser.email}
                onChange={(e) => handleChangeBusinessUser('email', e.target.value)}
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="next-button-container">
          <Button
            disabled={!!(validationErrors && validationErrors.length)}
            color="info"
            size="medium"
            onClick={onSubmit}
            variant="contained"
            className="next-button-green"
          >
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
