import React, { useContext, useState } from 'react';
import { PackingListLineItem, PackingList } from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';

interface PackingListParsedViewProps {
  packingList: PackingList;
  onPackingListChanged: (arg0: PackingList) => void;
}

const packingListLineItemsStyles = {
  headerCellStyles: {
    backgroundColor: 'lightgray',
    padding: '8px!important',
    marginRight: '2px',
    fontSize: '12px!important',
    fontWeight: '700',
    color: 'black',
  },
  icon: {
    fontSize: '14px',
  },
  itemsTableContainer: {
    marginTop: '16px',
    '& .MuiTableCell-root': {
      padding: '4px!important',
    },
  },
  itemsTableRow: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
};

const managePackingListLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'PO #',
      viewComponent: (item: PackingListLineItem) => <>{item.poNumber}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="PO #"
          value={item.poNumber}
          onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'SKU',
      viewComponent: (item: PackingListLineItem) => <>{item.sku}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="SKU"
          value={item.sku}
          onChange={(e) => setItem({ ...item, sku: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Description',
      viewComponent: (item: PackingListLineItem) => <>{item.description}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="Description"
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item: PackingListLineItem) => <>{item.unitQuantity}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="Quantity"
          value={item.unitQuantity}
          onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: '# Cartons',
      viewComponent: (item: PackingListLineItem) => <>{item.cartonCount}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="# Cartons"
          value={item.cartonCount}
          onChange={(e) => setItem({ ...item, cartonCount: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Net Wt.',
      viewComponent: (item: PackingListLineItem) => <>{item.netWeight}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="Net Weight"
          value={item.netWeight}
          onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Gross Wt.',
      viewComponent: (item: PackingListLineItem) => <>{item.grossWeight}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          label="Gross Wt."
          value={item.grossWeight}
          onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
  ],
};

const PackingListParsedView: React.FC<PackingListParsedViewProps> = ({ packingList, onPackingListChanged }) => {
  const { loadingStatus, setLoadingStatus } = useContext(EntryPrepTabContext);

  const [modifyMode, setModifyMode] = useState<{ [key: string]: boolean }>({
    importer: false,
    supplier: false,
    manufacturer: false,
    invoiceNumber: false,
    invoiceDate: false,
  });

  const [editingField, setEditingField] = useState<string | null>(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({
    invoiceNumber: packingList.invoiceNumber || 'no invoice number',
    invoiceDate: formatMMMDDYYYYDate(packingList.invoiceDate?.toString() || 'no invoice date'),
  });

  const handleFieldChange = (field: string, value: string) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditClick = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseEnter = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseLeave = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: false }));
  };

  const renderCommercialInvoiceField = (field: string, displayValue: string) => {
    const isHovered = modifyMode[field];

    return (
      <div
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={() => handleMouseLeave(field)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{displayValue}</div>
        {isHovered && (
          <IconButton
            onClick={() => setModifyMode((prev) => ({ ...prev, [field]: true }))}
            size="small"
            sx={{ padding: '0px', marginLeft: '2px' }}
          >
            <EditIcon style={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Box style={styles.parsedViewContainer}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', color: 'black', gap: '8px', marginBottom: '24px' }}
      >
        <div style={{ fontSize: '12px' }}>
          <b>Invoice Number</b>
          <div style={{ color: '#525256' }}>
            {renderCommercialInvoiceField('invoiceNumber', packingList.invoiceNumber || 'no invoice number')}
          </div>
        </div>
        <div>
          <h4 style={styles.parsedViewHeader}>Packing List</h4>
          <div style={styles.parsedViewSubheader}>
            Foot Accessory Manufacturing Inc, 1234 Factory Street, Shenzhen, China
          </div>
        </div>
        <div style={{ fontSize: '12px' }}>
          <b>Invoice Date</b>
          <div style={{ color: '#525256' }}>
            {renderCommercialInvoiceField(
              'invoiceDate',
              formatMMMDDYYYYDate(packingList.invoiceDate?.toString()) || 'no invoice date'
            )}
          </div>
        </div>
      </div>

      <div style={styles.parsedViewSummarySection}>
        <div style={styles.parsedViewSummarySectionColumn}></div>
        {['importer', 'supplier'].map((section) => (
          <div
            style={styles.parsedViewSummarySectionColumn}
            onMouseEnter={() => handleMouseEnter(section)}
            onMouseLeave={() => handleMouseLeave(section)}
          >
            <div style={styles.parsedViewSummarySectionColumnHeader}>
              {section === 'importer' && 'Importer of Record'}
              {section === 'supplier' && 'Supplier Information'}
            </div>
            <div>
              {section === 'importer' && (
                <>
                  {packingList.importerOfRecordName}
                  <br /> {packingList.importerOfRecordStreet}
                  <br /> {packingList.importerOfRecordCity}, {packingList.importerOfRecordState}{' '}
                  {packingList.importerOfRecordZip}
                  <br />
                  {packingList.importerOfRecordCountry}
                </>
              )}
              {section === 'supplier' && (
                <>
                  {packingList.supplierName}
                  <br /> {packingList.supplierStreet}
                  <br /> {packingList.supplierCity}, {packingList.supplierState} {packingList.supplierZip}
                  <br />
                  {packingList.supplierCountry}
                </>
              )}
            </div>
            {modifyMode[section] && (
              <IconButton style={styles.editIconButton} onClick={() => handleEditClick(section)} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      <ManageItems<PackingListLineItem>
        manageItemsConfig={managePackingListLineItemsConfig}
        items={packingList.lineItems}
        getDefaultItem={() => ({
          poNumber: '',
          sku: '',
          description: '',
          materialComposition: '',
          unitQuantity: '',
          unitValue: '',
          currencyCode: '',
          totalValue: '',
          countryOfOrigin: '',
          additionalProperties: {},
          containerNumber: '',
          htsno: '',
          unitWeight: '',
          netWeight: '',
          grossWeight: '',
          cartonCount: '',
        })}
        itemName=""
        styles={packingListLineItemsStyles}
        useDynamicColumnHeader={true}
        useDeleteConfirmationModal={true}
        onItemsUpdated={(items) => {
          onPackingListChanged({ ...packingList, lineItems: items });
        }}
        parentLoadingStatus={loadingStatus}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: 'black',
          fontSize: '12px',
          padding: '6px',
          marginTop: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid lightgrey',
          borderRadius: '4px',
        }}
      >
        <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
        <div>TOTAL (TODO)</div>
      </div>
    </Box>
  );
};

export default PackingListParsedView;
