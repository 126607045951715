import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ManifestModal from '@/components/manifest-modal/ManifestModal';
import { userHasAccessToPlan } from '@/utils/activePlanUtils';
import { FeatureName } from 'common/interfaces/plan';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';

export interface NavItem {
  icon: React.JSX.Element;
  displayName: string;
  routerPath: string;
  element?: React.JSX.Element;
  children?: NavItem[];
  onClick?: () => void;
  betaFeature?: string;
  planRules?: {
    featureName: FeatureName;
    disabledMessage: string;
    onClickDisabled: () => void;
  };
}

export type DashboardProps = {
  navItems: NavItem[];
  selectedNavItem?: string;
  handleOpenPlansModal?: () => void;
  open: boolean;
  isMenuOpen?: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;

  onNavItemSelected: (navItem: NavItem) => void;
  setShowSideMenu?: (val: boolean) => void;
  isBrokerApp?: boolean;
  underNavItemElement?: React.JSX.Element;
};

export default function NavMenu({
  navItems,
  handleOpenPlansModal,
  onNavItemSelected,
  selectedNavItem,
  isBrokerApp,
  isMenuOpen,
  open,
  underNavItemElement,
}: DashboardProps) {
  const [manifestModalOpen, setManifestModalOpen] = useState(false);

  const activePlan = useActivePlan();

  const navItemsWithChildren = navItems.reduce((acc, item) => {
    if (item.children) {
      acc[item.displayName] = false;
    }
    return acc;
  }, {});

  const [navItemsWithChildrenDropdownState, setNavItemsWithChildrenDropDownState] = useState(navItemsWithChildren);

  const handleManifestModalClose = () => {
    setManifestModalOpen(false);
  };

  const handleManifestModalOpen = () => {
    setManifestModalOpen(true);
  };

  type Callback = () => void;
  const getOnClick = (navItem: NavItem): Callback => {
    if (navItem.children) {
      return () => {
        const dropDownState = navItemsWithChildrenDropdownState[navItem.displayName];
        const copy = { ...navItemsWithChildrenDropdownState };
        copy[navItem.displayName] = !dropDownState;
        setNavItemsWithChildrenDropDownState(copy);
      };
    } else if (navItem.onClick && !navItem.element) {
      return navItem.onClick;
    } else if (navItem.planRules) {
      if (!userHasAccessToPlan(navItem.planRules.featureName, activePlan.metadata)) {
        return navItem.planRules?.onClickDisabled;
      }
    }

    return () => {
      onNavItemSelected(navItem);
    };
  };

  const shouldShowChildren = (navItem: NavItem) => {
    return navItem.children && navItemsWithChildrenDropdownState[navItem.displayName];
  };

  const renderNavItem = (navItem: NavItem, depth: number) => {
    const isDisabled = navItem.planRules
      ? !userHasAccessToPlan(navItem.planRules?.featureName, activePlan.metadata)
      : false;
    return (
      <React.Fragment key={navItem.displayName}>
        <Tooltip
          disableHoverListener={!isDisabled}
          title={navItem.planRules?.disabledMessage}
          placement="right"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -40], // Adjusts the tooltip 40px to the left from the right placement
                },
              },
            ],
          }}
        >
          <span
            style={{ paddingLeft: 20 * depth + 'px' }}
            className={`custom-list ${selectedNavItem === navItem.displayName ? 'active' : ' '}`}
            key={navItem.displayName}
          >
            <Button
              className={isDisabled ? 'nav-item-disabled' : ''}
              variant="text"
              sx={{ textTransform: 'none', minWidth: '0' }}
              startIcon={navItem.icon}
              endIcon={
                navItem.children ? (
                  navItemsWithChildrenDropdownState[navItem.displayName] ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowLeftIcon />
                  )
                ) : null
              }
              onClick={getOnClick(navItem)}
            >
              {isMenuOpen ? <div className="menu-button-text-container">{navItem.displayName}</div> : <></>}
            </Button>
          </span>
        </Tooltip>
        {shouldShowChildren(navItem)
          ? navItem.children!.map((child) => {
              return renderNavItem(child, depth + 1);
            })
          : null}
      </React.Fragment>
    );
  };
  return (
    <>
      <div className="profile-main-content">
        <div
          className="menu-item-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {navItems.map((navItem) => renderNavItem(navItem, 0))}
        </div>
      </div>
      <ManifestModal open={manifestModalOpen} onClose={handleManifestModalClose} />
      {underNavItemElement}
    </>
  );
}
