import Axios, { AxiosInstance } from 'axios';
import * as ls from 'local-storage';
import { Product } from 'common/interfaces/product';
import { ProductFieldsConfig } from 'common/interfaces/productfields';
import { CalChatDefault, CalChatMessage } from 'common/interfaces/calChat';
import { BusinessUser, BusinessWithDocuments, ComplianceProfile } from 'common/interfaces/business';
import { OfferedPurchaseAny, UsagePlanAny, UsagePlanMetadata } from 'common/interfaces/plan';
import { User, UserSettingName } from 'common/interfaces/user';
import { VMCR } from 'common/interfaces/vmcr';
import { DataExportProductCSV, ProductCSVDataExportProperties } from 'common/interfaces/dataExport';
import { Entry, EntryInvoice } from 'common/interfaces/entry';
import { CargoManifestQuery } from 'common/interfaces/aceDataSource';
import { ProductsDataImport } from 'common/interfaces/dataimport';
import { CustomsOnboarding } from 'common/interfaces/customsOnboarding';
import { UserRegistrationForm } from 'common/interfaces/auth';
import { CreateSessionRequest, Link, LoadSessionResponse, Session } from 'common/interfaces/session';
import { ComplianceSummaryContext } from 'common/interfaces/complianceSummary';
import { ShipmentMetadata, ShipmentWithDocuments } from 'common/interfaces/shipment';
import {
  Metric,
  Report,
  ReportingCollection,
  ReportingManifest,
  ReportType,
  TimePeriod,
} from 'common/interfaces/reports';
import { TableConfig } from 'common/interfaces/importalTable';
import { Document } from 'common/interfaces/document';
import { EntrySummary } from 'common/interfaces/entrySummary';
import { Classification, ClassificationPopulated } from 'common/interfaces/classification';
import {
  CommercialInvoice,
  CommercialInvoiceLineItem,
  CommercialInvoiceWithDocument,
  EntryPrepDocWithDocument,
} from 'common/interfaces/documentParsing';
import { ImportedProduct } from 'common/interfaces/importedProduct';

type SearchCountListener = (number) => void;

export class APICallbacks {
  onTokenExpired?: () => void;
  searchCountListener?: SearchCountListener;
}

export class ImportalAPI {
  private axios: AxiosInstance;
  private websocketInstance?: WebSocket;
  private callbacks: APICallbacks;
  private token: string = '';

  constructor(callbacks: APICallbacks) {
    this.callbacks = callbacks;
    this.axios = Axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
    this.token = ls.get('token');

    this.axios.interceptors.request.use(async (config) => {
      const existingHeaders = config.headers;
      config.headers = {
        Authorization: `Bearer ${this.token}`,
        ...existingHeaders,
      };
      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        const searchCountValue = parseInt(response.headers['search-count']);
        if (searchCountValue) {
          this.callbacks.searchCountListener?.(searchCountValue);
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const errorResponse = error.response;
          if (errorResponse.data && errorResponse.data.error === 'Token Expired') {
            // backend is telling us that our token is expired, throw it out and redirect to login
            this.callbacks.onTokenExpired && this.callbacks.onTokenExpired(); // hacky way to call optional func, would prefer this.callbacks.onTokenExpired?()
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private websocketLock: Promise<WebSocket> | null = null; // Lock to prevent concurrent WebSocket creation
  private pingInterval: number | null = null; // Use number for browser-based setInterval

  private startWebsocketPing = () => {
    if (!this.websocketInstance) return;

    // Clear any existing ping intervals before starting a new one
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }

    // Send a ping every 30 seconds
    this.pingInterval = window.setInterval(() => {
      if (this.websocketInstance?.readyState === WebSocket.OPEN) {
        console.debug('Sending ping to keep connection alive');
        this.websocketInstance.send(JSON.stringify({ eventType: 'PING' }));
      }
    }, 30000); // Ping every 30 seconds
  };

  private stopWebsocketPing = () => {
    if (this.pingInterval !== null) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  };

  private createWebsocketConnection = (): Promise<WebSocket> => {
    if (this.websocketLock) {
      return this.websocketLock;
    }

    // Set up the lock
    this.websocketLock = new Promise((resolve, reject) => {
      const wsURL = `${this.getBaseURL()}/events/ws?token=${encodeURIComponent(this.token)}`;
      this.websocketInstance = new WebSocket(wsURL);

      this.websocketInstance.onopen = () => {
        console.debug('WebSocket connection established, resolving websocketInstance', this.websocketInstance);

        this.startWebsocketPing();

        if (this.websocketInstance) {
          resolve(this.websocketInstance);
        } else {
          reject(new Error('websocket instance is undefined during onopen?'));
        }
        this.websocketLock = null; // Clear the lock after resolving
      };

      this.websocketInstance.onerror = (error) => {
        console.error('WebSocket error', error);
        this.stopWebsocketPing();
        reject(new Error('WebSocket connection failed'));
        this.websocketInstance = undefined; // Reset the instance if connection fails
        this.websocketLock = null; // Clear the lock on error
      };

      this.websocketInstance.onclose = (event) => {
        console.debug('WebSocket connection closed', event.reason);
        this.stopWebsocketPing(); // Stop pinging when connection closes
        this.websocketInstance = undefined; // Reset the instance when connection closes
        this.websocketLock = null; // Clear the lock on close
      };
    });

    return this.websocketLock;
  };

  public getWebsocketConnection = async (): Promise<WebSocket> => {
    if (this.websocketInstance && this.websocketInstance.readyState === WebSocket.OPEN) {
      console.debug('Reusing existing WebSocket connection');
      return this.websocketInstance;
    } else {
      return this.createWebsocketConnection();
    }
  };

  getBaseURL = (): string => {
    return this.axios.defaults.baseURL!;
  };

  addSearchCountListener = (callback: SearchCountListener) => {
    this.callbacks.searchCountListener = callback;
  };

  setToken = (token: string) => {
    ls.set('token', token);
    this.token = token;
  };

  getDataImports = () => {
    return this.axios.get('/data-import/');
  };

  createNewDataImport = () => {
    return this.axios.post('/data-import');
  };

  uploadFilesDataImport = (id: string, files: File[]) => {
    let formData = new FormData();

    // TODO (cage): iterate through files
    formData.append('importFile', files[0]);

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return this.axios.post(`/data-import/${id}/upload-file`, formData, config);
  };

  previewDataImport(id: string) {
    return this.axios.get(`/data-import/${id}/preview`, {});
  }

  submitDataImport = (id: string) => {
    return this.axios.post(`/data-import/${id}/submit`, {});
  };

  getProducts() {
    return this.axios.get('/products', {});
  }

  deleteProducts() {
    return this.axios.delete('/products', {});
  }

  createProductExport(params: ProductCSVDataExportProperties) {
    return this.axios.post<DataExportProductCSV>('/data-export', params);
  }

  getProductByID(id: string) {
    return this.axios.get(`/products/${id}`, {});
  }

  createProduct(product: Product, businessId?: string) {
    return this.axios.post('/products', product, { params: { businessId } });
  }

  deleteProduct(id: string) {
    return this.axios.delete(`/products/${id}`);
  }

  updateProduct(id: string, product: any) {
    return this.axios.put(`/products/${id}`, product);
  }

  recordImportedProduct(
    productId: string,
    invoiceData: {
      invoiceNumber: string;
      invoiceDate: Date;
      commercialInvoice: CommercialInvoiceWithDocument;
      lineItem: CommercialInvoiceLineItem;
    },
    businessId: string
  ) {
    return this.axios.post<ImportedProduct>(`/products/${productId}/record-imported-product`, invoiceData, {
      params: { businessId },
    });
  }

  getProductForRecordedProductImported(importedProductId: string) {
    return this.axios.get<Product>(`/products`, { params: { importedProductId } });
  }

  getComplianceFactSheetRow(htsno: string) {
    return this.axios.get(`hts/${htsno}/compliance-fact-sheet-data`);
  }

  getProductComplianceSummary(productId: string) {
    return this.axios.get(`/products/${productId}/compliance-summary`);
  }

  getComplianceSummary(type: ComplianceSummaryRoute, input: any) {
    return this.axios.post<ComplianceSummaryContext>(`compliance-summary/${type}`, { input });
  }

  getActiveProductUploads() {
    return this.axios.get<ProductsDataImport[]>('/data-import', {
      params: {
        status: ['PROCESSING', 'SUBMITTED_PENDING'],
      },
    });
  }

  createTempDocument = (document: Document, file: File, businessId?: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(document));

    return this.axios.post<Document>('/documents', formData, { params: { isTempFile: true, businessId } });
  };

  createDocument = (document: Document, file: File, businessId?: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(document));
    return this.axios.post<Document>('/documents', formData, { params: { businessId } });
  };

  getDocument = (documentId: string) => {
    return this.axios.get<Document>(`/documents/${documentId}`);
  };

  getDocumentSignedURL = (documentId: string) => {
    return this.axios.get<string>(`/documents/${documentId}/signed-url`);
  };

  updateDocument = (documentId: string, document?: Document, file?: File) => {
    let formData = new FormData();
    if (!file && !document) {
      throw new Error('cannot update document without document or file');
    }
    if (file) {
      formData.append('file', file);
    }
    if (document) {
      formData.append('document', JSON.stringify(document));
    }

    return this.axios.put<Document>(`/documents/${documentId}`, formData);
  };

  getShipmentsForUser = () => {
    return this.axios.get(`/shipments/user`);
  };

  getShipmentsForBroker = () => {
    return this.axios.get(`/shipments/broker`);
  };

  createShipment = (shipment: ShipmentWithDocuments) => {
    return this.axios.post<ShipmentWithDocuments>('/shipments', shipment);
  };

  createShipmentViaBroker = (shipment: ShipmentWithDocuments) => {
    return this.axios.post<ShipmentWithDocuments>('/shipments/broker', shipment);
  };

  updateShipment = (id: string, shipment: ShipmentWithDocuments) => {
    return this.axios.put<ShipmentWithDocuments>(`/shipments/${id}`, shipment);
  };

  getShipmentById = (id: string) => {
    return this.axios.get<ShipmentWithDocuments>(`/shipments/${id}`);
  };

  getShipmentMetadata = (id: string) => {
    return this.axios.get<ShipmentMetadata>(`/shipments/${id}/metadata`);
  };

  getLatestShipmentReferenceNumber = () => {
    return this.axios.get<string>('/shipments/latest');
  };

  createShipmentTrackingReference = (id: string) => {
    return this.axios.post<ShipmentWithDocuments>(`/shipments/${id}/tracking-reference`);
  };

  deleteShipment(id: string) {
    return this.axios.delete(`/shipments/${id}`);
  }

  getActiveBetaFeaturesForUser = () => {
    return this.axios.get(`/users/beta-features`);
  };

  getProductFields = () => {
    return this.axios.get<ProductFieldsConfig>(`/products/fields`);
  };

  getProductFieldsForBusiness = (businessId: string) => {
    return this.axios.get<ProductFieldsConfig>(`/products/fields`, { params: { businessId } });
  };

  updateProductFields = (productFieldsConfig: ProductFieldsConfig) => {
    return this.axios.put(`/products/fields`, productFieldsConfig);
  };

  createNewChat = () => {
    return this.axios.post<CalChatDefault>(`/cal/chats`);
  };

  getUserChats = () => {
    return this.axios.get<Array<CalChatDefault>>(`/cal/chats`);
  };

  addMessageToChat = (chatId: string, content: string) => {
    return this.axios.post(`/cal/chats/${chatId}/messages`, { content });
  };

  getAllChatMessages = (chatId: string) => {
    return this.axios.get<Array<CalChatMessage>>(`/cal/chats/${chatId}/messages`);
  };

  createBusiness = (business: BusinessWithDocuments) => {
    return this.axios.post<BusinessWithDocuments>(`/businesses`, business);
  };

  getComplianceNotifications = (businessId: string) => {
    return this.axios.get(`/businesses/${businessId}/compliance-notifications`);
  };

  createBusinessViaBroker = (business: any) => {
    return this.axios.post<any>(`/businesses/broker`, business);
  };

  createBusinessUser = (id: string, user: User) => {
    return this.axios.post(`/businesses/${id}/users`, user);
  };

  updateBusinessUser = (id: string, user: User) => {
    return this.axios.put(`/businesses/${id}/users`, user);
  };

  deleteBusinessUser = (id: string, businessId: string) => {
    return this.axios.delete(`/businesses/${businessId}/users/${id}`);
  };

  createBusinessViaOnboarding = (business: BusinessWithDocuments) => {
    return this.axios.post<BusinessWithDocuments>(`/businesses`, business, {
      params: {
        isCustomsOnboarding: true,
      },
    });
  };

  updateBusinessViaOnboarding = (id: string, business: BusinessWithDocuments) => {
    return this.axios.put<BusinessWithDocuments>(`/businesses/${id}`, business, {
      params: {
        isCustomsOnboarding: true,
      },
    });
  };

  addressVerification = (query) => {
    return this.axios.post<{ results: any }>(`/businesses/address-verification`, query);
  };

  updateBusiness = (id: string, business: BusinessWithDocuments) => {
    return this.axios.put(`/businesses/${id}`, business);
  };

  getAllBusinesses = () => {
    return this.axios.get<BusinessWithDocuments[]>(`/businesses`);
  };

  getBusinessById = (id: string) => {
    return this.axios.get<BusinessWithDocuments>(`/businesses/${id}`);
  };

  getBusinessUsers = (id: string) => {
    return this.axios.get<Array<BusinessUser>>(`/businesses/${id}/users`);
  };

  getBusinessShipments = (id: string) => {
    return this.axios.get(`/businesses/${id}/shipments`);
  };

  getDocumentActions = (id: string) => {
    return this.axios.get(`/actions/document/${id}`);
  };

  getVizionReferenceCountForThisMonth = () => {
    return this.axios.get<{ totalReferences: number }>('/shipments/references-count');
  };

  getPlanForBusiness = (id: string) => {
    return this.axios.get<UsagePlanAny>(`/businesses/${id}/plan`);
  };

  getPlanMetadataForBusiness = (id: string) => {
    return this.axios.get<UsagePlanMetadata>(`/businesses/${id}/plan-metadata`);
  };

  updateBusinessUsers = (id: string, users: Array<BusinessUser>) => {
    return this.axios.put<Array<BusinessUser>>(`/businesses/${id}/users`, users);
  };

  searchBusinessesByName = (name: string) => {
    return this.axios.get<BusinessWithDocuments[]>(`/businesses/search`, {
      params: { name },
    });
  };

  getActivePlan = () => {
    return this.axios.get<UsagePlanAny>(`/plans/active`);
  };

  getActivePlanMetadata = () => {
    return this.axios.get<UsagePlanMetadata>(`/plans/active-metadata`);
  };

  getActiveBusiness = () => {
    return this.axios.get<BusinessWithDocuments[]>(`/businesses/user/current`);
  };
  getActiveUser = () => {
    return this.axios.get<User>(`/users/active`);
  };

  getAllOfferedPlans = () => {
    return this.axios.get<OfferedPurchaseAny[]>(`/plans`);
  };

  cancelPurchase = (values: { reason: string }) => {
    return this.axios.put(`/purchases/cancel`, values);
  };

  pausePurchase = () => {
    return this.axios.put(`/purchases/pause`);
  };

  login = (loginData: { email: string; password: string }) => {
    return this.axios.post(`/auth/login`, loginData);
  };

  getSavedCardsForPlan = () => {
    return this.axios.get(`/plans/active/cards`);
  };

  saveNewCardToPlan = (card: any) => {
    return this.axios.post(`/plans/active/cards`, card);
  };

  deleteCardFromActivePlan = (card: any) => {
    return this.axios.delete(`/plans/active/cards`, card);
  };

  setActivePlanDefaultCard = (card: any) => {
    return this.axios.post(`/plans/active/default-card`, card);
  };

  createPurchase = (data: { plan: string; coupon?: string }) => {
    return this.axios.post(`/purchases`, data);
  };

  createVCMR = (manifestRequest: VMCR) => {
    return this.axios.post(`/businesses/request-vmc`, manifestRequest);
  };

  getOfferedVMCRPurchases = () => {
    return this.axios.get(`/purchases`, { params: { categories: 'VMCR' } });
  };

  getOfferedPlans = () => {
    return this.axios.get(`/purchases`, { params: { categories: 'PLAN' } });
  };

  createEntry = () => {
    return this.axios.get<Entry>(`/entries`);
  };

  updateEntry = (id: string, entry: Entry) => {
    return this.axios.put(`/entries/${id}`, entry);
  };

  getEntries = () => {
    return this.axios.get(`/entries/user-entries`);
  };

  getEntriesForBroker = () => {
    return this.axios.get(`/entries/broker`);
  };

  getEntryById = (id: string) => {
    return this.axios.get<Entry>(`/entries/${id}`);
  };

  getEntryByShipmentReferenceNumber = (referenceNumber: string) => {
    return this.axios.get<Entry>(`/entries/shipment/reference-number/${referenceNumber}`);
  };

  getEntriesByShipmentId = (id: string) => {
    return this.axios.get<Entry[]>(`/entries/shipment/id/${id}`);
  };

  getCargoManifest = (id: string, query: CargoManifestQuery) => {
    return this.axios.post(`/shipments/${id}/cargo-manifest-query`, query);
  };

  getEntryMetadata = (id: string) => {
    return this.axios.get(`/entries/${id}/entry-metadata`);
  };

  getProductCSVDataExports = () => {
    return this.axios.get<DataExportProductCSV[]>(`/data-export`);
  };

  deleteDataExport = (dataExportId: string) => {
    return this.axios.delete(`/data-export/${dataExportId}`);
  };

  previewDataExportFile = (dataExportId: string) => {
    return this.axios.get<string>(`/data-export/${dataExportId}/preview`);
  };

  getDataExport = (dataExportId: string) => {
    return this.axios.get<DataExportProductCSV>(`/data-export/${dataExportId}`);
  };

  downloadDataExportFile = (dataExportId: string) => {
    return this.axios.request<Blob>({
      method: 'get',
      url: `/data-export/${dataExportId}/download`,
      responseType: 'blob',
    });
  };

  calculateComplianceSummaries = () => {
    return this.axios.post(`/products/compliance-summary-calculation`);
  };

  getActiveCalculations = () => {
    return this.axios.get<any[]>(`/products/compliance-summary-calculation`);
  };

  getUserSettings = () => {
    return this.axios.get<Record<UserSettingName, any>>(`/users/active/settings`);
  };

  setUserSettings = (settings: Record<UserSettingName, any>) => {
    return this.axios.put<Record<UserSettingName, any>>(`/users/active/settings`, settings);
  };

  getDocumentData = (documentId: string) => {
    return this.axios.request<Blob>({
      method: 'get',
      url: `/documents/${documentId}/data`,
      responseType: 'blob',
    });
  };

  getDocumentURL = (document: Document) => {
    return this.getBaseURL() + '/tokenToCookie' + `?token=${this.token}&nextRoute=/documents/${document._id}/data`;
  };

  getJobsAdminPanelURL = () => {
    return this.getBaseURL() + '/tokenToCookie' + `?token=${this.token}&nextRoute=/admin/queues`;
  };

  updateCustomsOnboarding = (id: string, customsOnboarding: any) => {
    return this.axios.put<CustomsOnboarding>(`/customs-onboarding/${id}`, customsOnboarding);
  };

  getCustomsOnboardingForBusiness = (id: string) => {
    return this.axios.get<CustomsOnboarding>(`/businesses/${id}/customs-onboarding`);
  };

  getEntriesForBusiness = (id: string) => {
    return this.axios.get(`/businesses/${id}/entries`);
  };

  addAuthorizedSignerPhotoId = (photoIdFile: any) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.axios.put(`/businesses/upload-files`, photoIdFile, config);
  };

  recordSignedPOA = (poaDetails: { EsignUrl: string; contractId: string; isEsign: boolean }) => {
    return this.axios.post(`/customs-onboarding/record-signed-poa`, poaDetails);
  };

  getActiveCustomsOnboarding = () => {
    return this.axios.get<CustomsOnboarding>(`/customs-onboarding/user/current`);
  };

  registerUser = (user: UserRegistrationForm) => {
    return this.axios.post('/auth/register', user);
  };

  submitAuthenticationCode = (codeObject: { authCode: string }) => {
    return this.axios.post('/auth/authenticated-code', codeObject);
  };

  createSession = (request: CreateSessionRequest) => {
    return this.axios.post<Session>(`/session`, request);
  };

  loadSession = (sessionId: string) => {
    return this.axios.get<LoadSessionResponse>(`/session/${sessionId}`);
  };

  deleteSession = (sessionId: string) => {
    return this.axios.delete(`/session/${sessionId}`);
  };

  getLinksForBusiness = (businessId: string) => {
    return this.axios.get<Link[]>(`/businesses/${businessId}/links`);
  };

  // REPORTING
  getReportsForUser = () => {
    return this.axios.get<Report[]>(`/reports`);
  };

  getReportsForBusiness = (businessId: string) => {
    return this.axios.get<Report[]>(`/businesses/${businessId}/reports`);
  };

  enableBusinessForReporting = (businessId: string) => {
    return this.axios.post<ReportingManifest>(`/businesses/${businessId}/reporting-manifest`);
  };

  getReportingManifestForCurrentUser = () => {
    return this.axios.get<ReportingManifest>(`/reports/reporting-manifest`);
  };

  getReportingManifestForBusiness = (businessId: string) => {
    return this.axios.get<ReportingManifest>(`/businesses/${businessId}/reporting-manifest`);
  };

  updateReportingManifestForBusiness = (businessId: string, reportingManifest: ReportingManifest) => {
    return this.axios.put<ReportingManifest>(`/businesses/${businessId}/reporting-manifest`, reportingManifest);
  };

  getCollectionsAvailableForReporting = () => {
    return this.axios.get<ReportingCollection[]>(`/reports/available-collections`);
  };

  createReport = (reportType: ReportType) => {
    return this.axios.post<Report>(`/reports`, { reportType });
  };

  deleteReport = (reportId: string) => {
    return this.axios.delete<string>(`/reports/${reportId}`);
  };

  requestReport = (userInput: string) => {
    return this.axios.post<Report>(`/reports/request-report`, { userInput });
  };

  getReport = (reportId: string) => {
    return this.axios.get<Report>(`/reports/${reportId}`);
  };

  getReportData = (reportId: string) => {
    return this.axios.get<any[]>(`/reports/${reportId}/data`);
  };

  downloadReportData = (reportId: string, tableConfig: TableConfig) => {
    return this.axios.request<Blob>({
      method: 'post',
      url: `/reports/${reportId}/data/download`,
      responseType: 'blob',
      data: tableConfig,
    });
  };

  testReportQuery = (reportId: string, query: any) => {
    return this.axios.post<any[]>(`/reports/${reportId}/test-query`, { query });
  };

  updateReport = (reportId: string, report: Report) => {
    return this.axios.put<Report>(`/reports/${reportId}`, report);
  };

  runReport = (reportId: string) => {
    return this.axios.post<string>(`/reports/${reportId}/run`);
  };

  getSummaryMetrics = (timePeriod: TimePeriod) => {
    return this.axios.get<Metric[]>('/reports/summary-metrics', { params: timePeriod });
  };

  // ENTRY SUMMARY

  getAllEntrySummariesForUser = () => {
    return this.axios.get<EntrySummary[]>(`/entry-summaries`);
  };

  getEntrySummariesForShipment = (shipmentId: string) => {
    return this.axios.get<EntrySummary[]>(`/entry-summaries`, { params: { shipment: shipmentId } });
  };

  updateEntrySummary = (entrySummaryId: string, updatedEntrySummary: EntrySummary) => {
    return this.axios.put<EntrySummary>(`/entry-summaries/${entrySummaryId}`, updatedEntrySummary);
  };

  deleteEntrySummary = (entrySummaryId: string) => {
    return this.axios.delete(`/entry-summaries/${entrySummaryId}`);
  };

  // ENTRY PREP DOC
  convertToEntryInvoice = (entryPrepDocs: EntryPrepDocWithDocument[], businessId: string) => {
    return this.axios.post<EntryInvoice>(`/entry-prep-docs/convert-to-entry-invoice`, entryPrepDocs, {
      params: { business: businessId },
    });
  };

  getEntryPrepDocsForShipment = (shipmentId: string) => {
    return this.axios.get<EntryPrepDocWithDocument[]>(`/entry-prep-docs`, {
      params: { shipment: shipmentId },
    });
  };

  updateEntryPrepDoc = (id: string, updatedDoc: EntryPrepDocWithDocument) => {
    return this.axios.put<EntryPrepDocWithDocument>(`/entry-prep-docs/${id}`, updatedDoc);
  };

  deleteEntryPrepDoc = (id: string) => {
    return this.axios.delete(`/entry-prep-docs/${id}`);
  };

  updateUserTermsAcceptance = (reset: boolean) => {
    const acceptanceStatus = !reset ? false : true;
    return this.axios.put(`/auth/accept-terms-conditions`, {
      hasAcceptedTermsAndConditions: acceptanceStatus,
      termsAndConditionsAcceptanceDate: new Date(),
    });
  };

  validateCoupon = (couponCode: string) => {
    return this.axios.post<{ discount: number }>('/purchases/validate-coupon', { coupon: couponCode });
  };

  getMostRecentUnsubmittedClassification = () => {
    return this.axios.get<{ classification?: ClassificationPopulated }>('/classifications/most-recent-unsubmitted');
  };

  createClassification = (classification: Classification) => {
    return this.axios.post<ClassificationPopulated>('/classifications', classification);
  };

  getClassificationById = (id: string) => {
    return this.axios.get<ClassificationPopulated>(`/classifications/${id}`);
  };

  updateClassificationWithId = (id: string, classification: Classification) => {
    return this.axios.put<ClassificationPopulated>(`/classifications/${id}`, classification);
  };

  deleteClassificationWithId = (id: string) => {
    return this.axios.delete(`/classifications/${id}`);
  };

  getUserClassifications = () => {
    return this.axios.get<Classification[]>(`/classifications/user-classifications`);
  };

  getClassificationsForBusiness = (businessId: string) => {
    return this.axios.get<Classification[]>(`/classifications`, { params: { businessId } });
  };

  getAllClassifications = () => {
    return this.axios.get<Classification[]>(`/classifications`);
  };

  getAssignedClassifications = () => {
    return this.axios.get<Classification[]>(`/classifications`, { params: { assigned: true } });
  };

  getAllUsersWithBrokerPermissions = () => {
    return this.axios.get<User[]>('/users', { params: { isBroker: true } });
  };

  updateComplianceProfile = (businessId: string, updatedCompliance: ComplianceProfile) => {
    return this.axios.put<ComplianceProfile>(`/businesses/${businessId}/compliance-profile`, updatedCompliance);
  };

  createEsign = (esignArgs: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userFullName: string;
    businessName: string;
    address: string;
    ein: string;
    signeeTitle: string;
    businessStructure: string;
    additionalState: string;
    redirect_url: string;
  }) => {
    return this.axios.post('/auth/esign', esignArgs);
  };
}

export enum ComplianceSummaryRoute {
  COMPLIANCE_ONE = 'compliance-one',
  DUTY_CALCULATION = 'duty-calculation',
  DUTY_MITIGATION = 'duty-mitigation',
  HDFS_SEARCH = 'hdfs-search',
  PGA_SEARCH = 'pga-search',
}

export enum TransportationType {
  OCEAN = 'OCEAN',
  AIR = 'AIR',
  TRUCK = 'TRUCK',
}
