import { IconButton } from '@mui/material';
import {
  Action,
  ActionType,
  CreateShipmentAction,
  ShipmentTrackingActionProperties,
  UpdateShipmentAction,
} from 'common/interfaces/action';
import { Shipment, vizionSupportedCarriers } from 'common/interfaces/shipment';
import { formatHumanReadableDate } from 'common/utilities/shipmentUtility';
import moment from 'moment-timezone';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

export function howLongAgo(dateString: string): string {
  const now = moment().tz('America/New_York');
  let date;

  if (moment(dateString, moment.ISO_8601, true).isValid()) {
    date = moment.parseZone(dateString).tz('America/New_York');
  } else if (moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
    date = moment.utc(dateString).tz('America/New_York');
  } else if (moment(dateString, 'MM/DD/YY', true).isValid()) {
    date = moment(dateString, 'MM/DD/YY').tz('America/New_York');
  } else {
    return '';
  }

  const diffDays = now.diff(date, 'days');

  if (diffDays === 0) {
    return 'today';
  } else if (diffDays === 1) {
    return 'yesterday';
  } else if (diffDays === 2) {
    return 'two days ago';
  } else if (diffDays === 3) {
    return 'three days ago';
  } else if (diffDays === 4) {
    return 'four days ago';
  } else if (diffDays === 5) {
    return 'five days ago';
  } else if (diffDays === 6) {
    return 'six days ago';
  } else if (diffDays <= 7) {
    return 'last week';
  } else {
    return 'more than one week ago';
  }
}

export const renderArrayWithCommas = (values) => {
  if (!Array.isArray(values)) {
    return null;
  }

  return values.map((value, index) => (
    <React.Fragment key={index}>
      {value}
      {index < values.length - 1 && ', '}
    </React.Fragment>
  ));
};

export const generateShipmentChangeText = (
  shipmentAction: CreateShipmentAction | UpdateShipmentAction
): [string, number] => {
  const diff = shipmentAction.properties || ({} as Shipment<any>);
  const changedProperties = Object.keys(diff);

  if (shipmentAction.type === ActionType.CREATE_SHIPMENT) {
    return [`Shipment was created with ${changedProperties.length} values added.`, changedProperties.length];
  } else if (shipmentAction.type === ActionType.UPDATE_SHIPMENT) {
    const propertiesText =
      changedProperties.length > 1
        ? `${changedProperties.slice(0, -1).join(', ')} and ${changedProperties.slice(-1)}`
        : changedProperties[0];

    return [
      `Shipment ${propertiesText} ${changedProperties.length > 1 ? 'were' : 'was'} updated.`,
      changedProperties.length,
    ];
  }

  return ['', 0];
};

export const generateShipmentTrackingChangeText = (
  action: Action<ShipmentTrackingActionProperties>
): [string, number] => {
  let changeCount = 0;

  if (action.type === ActionType.CREATE_TRACKING) {
    // Handle the CreateShipmentTrackingAction case
    // Since CreateShipmentTrackingActionProperties is an empty object, just return a generic creation message
    return ['Tracking reference created.', changeCount];
  }

  if (action.type === ActionType.UPDATE_TRACKING) {
    const trackingAction = action.properties as ShipmentTrackingActionProperties;

    // Ensure trackingAction is not empty
    if (!trackingAction || Object.keys(trackingAction).length === 0) {
      return ['No changes detected in tracking update.', changeCount];
    }

    const etdText = trackingAction.etd ? `ETD was updated to ${formatHumanReadableDate(trackingAction.etd)}` : '';
    const etaText = trackingAction.eta ? `ETA was updated to ${formatHumanReadableDate(trackingAction.eta)}` : '';
    let statusText = '';

    if (etdText && !etaText && !trackingAction.departed && !trackingAction.arrived) {
      return [`${etdText}.`, 1];
    }

    if (!etdText && etaText && !trackingAction.departed && !trackingAction.arrived) {
      return [`${etaText}.`, 1];
    }

    if (!etdText && !etaText && trackingAction.departed && !trackingAction.arrived) {
      return ['The shipment has departed the origin port.', 1];
    }

    if (!etdText && !etaText && !trackingAction.departed && trackingAction.arrived) {
      return ['The shipment has arrived at the destination port.', 1];
    }

    // If multiple fields are updated, use the general update message.
    if (trackingAction.departed && trackingAction.arrived) {
      statusText = 'The shipment has departed and arrived.';
    } else if (trackingAction.departed && !trackingAction.arrived) {
      statusText = 'The shipment has departed but has not arrived yet.';
    } else if (!trackingAction.departed && trackingAction.arrived) {
      statusText = 'The shipment has not departed yet but has arrived.';
    }

    const generalUpdateMessage = `Shipment tracking updated. ${etdText}, ${etaText}, and ${statusText}`;
    changeCount = [trackingAction.etd, trackingAction.eta].filter(Boolean).length;
    if (trackingAction.departed) changeCount++;
    if (trackingAction.arrived) changeCount++;
    return [generalUpdateMessage.trim(), changeCount];
  }

  // Default return if action type doesn't match expected types
  return ['', changeCount];
};

export const renderCommercialInvoiceField = (
  field: string,
  displayValue: string,
  modifyMode: { [key: string]: boolean },
  setModifyMode: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  const handleMouseEnter = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseLeave = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: false }));
  };

  const isHovered = modifyMode[field];

  return (
    <div
      onMouseEnter={() => handleMouseEnter(field)}
      onMouseLeave={() => handleMouseLeave(field)}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div style={{ flex: 1 }}>{displayValue}</div>
      {isHovered && (
        <IconButton
          onClick={() => setModifyMode((prev) => ({ ...prev, [field]: true }))}
          size="small"
          sx={{ padding: '0px', marginLeft: '2px' }}
        >
          <EditIcon style={{ fontSize: '14px' }} />
        </IconButton>
      )}
    </div>
  );
};

export const buildIsfNumberNetchbUrl = (isfNumber: string): string => {
  // Construct the URL
  const url = `https://www.netchb.com/app/isf/viewIsfEntry.do?systemId=${isfNumber}`;
  return url;
};
