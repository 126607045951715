import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CommercialInvoiceLineItem, CommercialInvoiceWithDocument } from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, Card, IconButton, TextField, Tooltip, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { useAPI } from '@/api/APIContext';
import AddOrEditAProductModal from '@/pages/product-library/AddOrEditAProductModal';
import { ProductAction } from 'common/interfaces/product';
import { ImportalRequiredFieldNames, ProductFieldsConfig } from 'common/interfaces/productfields';
import { Inventory } from '@mui/icons-material';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

interface CommercialInvoiceParsedViewProps {
  commercialInvoice: CommercialInvoiceWithDocument;
  onCommercialInvoiceChanged: (arg0: CommercialInvoiceWithDocument) => void;
}

const commercialInvoiceLineItemsStyles = {
  headerCellStyles: {
    backgroundColor: 'lightgray',
    padding: '8px!important',
    marginRight: '2px',
    fontSize: '12px!important',
    fontWeight: '700',
    color: 'black',
  },
  icon: {
    fontSize: '14px',
  },
  itemsTableContainer: {
    marginTop: '16px',
    '& .MuiTableCell-root': {
      padding: '4px!important',
    },
  },
  itemsTableRow: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
};

const CommercialInvoiceParsedView: React.FC<CommercialInvoiceParsedViewProps> = ({
  commercialInvoice,
  onCommercialInvoiceChanged,
}) => {
  const theme = useTheme();
  const api = useAPI();
  const { businessId } = useContext(ShipmentPageContext);
  const { loadingStatus, setLoadingStatus } = useContext(EntryPrepTabContext);
  const [addOrEditAProductOpen, setAddOrEditAProductOpen] = useState(false);
  const [productIDForViewingInModal, setProductIdForViewingInModal] = useState<string | undefined>();
  const [commercialInvoiceLineForUpsertProduct, setCommercialInvoiceLineForUpsertProduct] = useState<
    CommercialInvoiceLineItem | undefined
  >(undefined);
  const [productFieldsConfig, setProductFieldsConfig] = useState<ProductFieldsConfig>();

  useEffect(() => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getProductFieldsForBusiness(businessId)
      .then(({ data }) => {
        setProductFieldsConfig(data);
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        console.error(err);
        console.error('error getting product fields config for business');
        setLoadingStatus(LoadingStatus.ERROR);
      });
  }, [businessId]);

  const onProductCreatedModifiedOrDeleted = (productId: string, action: ProductAction) => {
    switch (action) {
      case ProductAction.CREATED:
        // if product was created in this instance, then we now need to create 'imported product'
        // and attach to the CommercialInvoiceLineItem
        if (!commercialInvoiceLineForUpsertProduct) return;

        setLoadingStatus(LoadingStatus.LOADING);
        api
          .recordImportedProduct(
            productId,
            {
              invoiceNumber: commercialInvoice.invoiceNumber,
              invoiceDate: commercialInvoice.invoiceDate,
              commercialInvoice: commercialInvoice,
              lineItem: commercialInvoiceLineForUpsertProduct,
            },
            businessId
          )
          .then(({ data: importedProduct }) => {
            // now update the commercialInvoiceLineItem
            const indexForUpdate = commercialInvoice.lineItems.findIndex(
              (line) => line._id!.toString() === commercialInvoiceLineForUpsertProduct._id!.toString()
            );
            const updatedLineItem = commercialInvoice.lineItems[indexForUpdate];
            updatedLineItem.importedProductConnection = importedProduct._id;
            const updatedCommercialInvoice = { ...commercialInvoice };
            updatedCommercialInvoice[indexForUpdate] = updatedLineItem;

            onCommercialInvoiceChanged(commercialInvoice);

            setCommercialInvoiceLineForUpsertProduct(undefined);
            setProductIdForViewingInModal(undefined);
            setAddOrEditAProductOpen(false);
            setLoadingStatus(LoadingStatus.NOT_LOADING);
          })
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after creation');
            console.error(err);
            setLoadingStatus(LoadingStatus.ERROR);
          });

        api
          .getProductByID(productId)
          .then(({ data: newProduct }) => {})
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after creation');
            console.error(err);
          });

        break;
      case ProductAction.MODIFIED:
        setLoadingStatus(LoadingStatus.LOADING);
        api
          .getProductByID(productId)
          .then(({ data: updatedProduct }) => {
            setLoadingStatus(LoadingStatus.NOT_LOADING);
          })
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after modification');
            console.error(err);
            setLoadingStatus(LoadingStatus.ERROR);
          });

        break;
      case ProductAction.DELETED:
        // TODO: interesting corner case with deleting.
        // if you are on a paginating page, and the product that was deleted is the only thing on that page
        // i.e. last page with one item,
        // then you would want to mess with the paginating controls while deleting the product
        // i.e. move the user to the last page with contents since the products list will shrink

        break;
      default:
        console.log('no op');
    }
  };

  const [modifyMode, setModifyMode] = useState<{ [key: string]: boolean }>({
    importer: false,
    supplier: false,
    manufacturer: false,
    invoiceNumber: false,
    invoiceDate: false,
  });

  const [editingField, setEditingField] = useState<string | null>(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({
    invoiceNumber: commercialInvoice.invoiceNumber,
    invoiceDate: formatMMMDDYYYYDate(commercialInvoice.invoiceDate.toString()),
  });

  const handleFieldChange = (field: string, value: string) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditClick = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseEnter = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseLeave = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: false }));
  };

  const computedProductPropertiesFromCommercialInvoiceLine = useMemo(() => {
    const properties = {};
    if (!commercialInvoiceLineForUpsertProduct) {
      return properties;
    }

    const primaryIdentifierField = productFieldsConfig?.fields.filter((field) => field.isPrimaryIdentifier);
    if (!primaryIdentifierField) return properties;

    properties[primaryIdentifierField[0].name] = commercialInvoiceLineForUpsertProduct.sku;
    properties[ImportalRequiredFieldNames.HTS] = commercialInvoiceLineForUpsertProduct.htsno;

    properties[ImportalRequiredFieldNames.COUNTRY_OF_ORIGIN] = commercialInvoiceLineForUpsertProduct.countryOfOrigin;

    properties[ImportalRequiredFieldNames.UNIT_COST] = commercialInvoiceLineForUpsertProduct.unitValue;

    properties[ImportalRequiredFieldNames.UNIT_COST_CURRENCY] = commercialInvoiceLineForUpsertProduct.currencyCode;

    properties[ImportalRequiredFieldNames.MATERIAL_COMPOSITION] =
      commercialInvoiceLineForUpsertProduct.materialComposition;

    properties[ImportalRequiredFieldNames.UNIT_WEIGHT] = commercialInvoiceLineForUpsertProduct.unitWeight;

    properties[ImportalRequiredFieldNames.UNIT_WEIGHT_UNITS] = 'kg';

    // keys from commercial invoice
    properties[ImportalRequiredFieldNames.SUPPLIER] = commercialInvoice.supplierName;

    return properties;
  }, [commercialInvoiceLineForUpsertProduct]);

  const renderCommercialInvoiceField = (field: string, displayValue: string) => {
    const isHovered = modifyMode[field];

    return (
      <div
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={() => handleMouseLeave(field)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{displayValue}</div>
        {isHovered && (
          <IconButton
            onClick={() => setModifyMode((prev) => ({ ...prev, [field]: true }))}
            size="small"
            sx={{ padding: '0px', marginLeft: '2px' }}
          >
            <EditIcon style={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </div>
    );
  };

  const manageCommercialInvoiceLineItemsConfig: ManageItemsConfig = {
    columns: [
      {
        header: 'PO #',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.poNumber}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="PO #"
            value={item.poNumber}
            onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Container #',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.containerNumber}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Container #"
            value={item.containerNumber}
            onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'SKU',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.sku}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="SKU"
            value={item.sku}
            onChange={(e) => setItem({ ...item, sku: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Description',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.description}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Description"
            value={item.description}
            onChange={(e) => setItem({ ...item, description: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'HTS',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.htsno}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="HTS Code"
            value={item.htsno}
            onChange={(e) => setItem({ ...item, htsno: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Quantity',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitQuantity}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Quantity"
            value={item.unitQuantity}
            onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Unit Value',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitValue}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Unit Value"
            value={item.unitValue}
            onChange={(e) => setItem({ ...item, unitValue: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Total Value',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.totalValue}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Total Value"
            value={item.totalValue}
            onChange={(e) => setItem({ ...item, totalValue: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'COO',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.countryOfOrigin}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="COO"
            value={item.countryOfOrigin}
            onChange={(e) => setItem({ ...item, countryOfOrigin: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Net Wt.',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.netWeight}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Net Weight"
            value={item.netWeight}
            onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Gross Wt.',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.grossWeight}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            label="Gross Wt."
            value={item.grossWeight}
            onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
    ],
    viewModeAdditionalIcons: [
      {
        icon: (item: CommercialInvoiceLineItem) => {
          const color = item.importedProductConnection ? theme.palette.primary.main : 'grey';
          const message: string = item.importedProductConnection
            ? 'This line item is connected to a product.'
            : 'No product connection.';

          return (
            <Tooltip title={message} placement={'top'}>
              <Inventory style={{ color }} />
            </Tooltip>
          );
        },
        onClick: (item: CommercialInvoiceLineItem, index) => {
          if (item.importedProductConnection) {
            api
              .getProductForRecordedProductImported(item.importedProductConnection.toString())
              .then(({ data: product }) => {
                setCommercialInvoiceLineForUpsertProduct(item);
                setProductIdForViewingInModal(product._id!.toString());
                setAddOrEditAProductOpen(true);
              })
              .catch((err) => {
                console.error('error getting product for recorded import', err);
              });
          } else {
            setCommercialInvoiceLineForUpsertProduct(item);
            setProductIdForViewingInModal(undefined);
            setAddOrEditAProductOpen(true);
          }
        },
      },
    ],
  };

  return (
    <>
      <Card>
        <Box style={styles.parsedViewContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'black',
              gap: '8px',
              marginBottom: '24px',
            }}
          >
            <div style={{ fontSize: '12px' }}>
              <b>Invoice Number</b>
              <div style={{ color: '#525256' }}>
                {renderCommercialInvoiceField('invoiceNumber', commercialInvoice.invoiceNumber)}
              </div>
            </div>
            <div>
              <h4 style={styles.parsedViewHeader}>Commercial Invoice</h4>
              <div style={styles.parsedViewSubheader}>
                Foot Accessory Manufacturing Inc, 1234 Factory Street, Shenzhen, China
              </div>
            </div>
            <div style={{ fontSize: '12px' }}>
              <b>Invoice Date</b>
              <div style={{ color: '#525256' }}>
                {renderCommercialInvoiceField(
                  'invoiceDate',
                  formatMMMDDYYYYDate(commercialInvoice.invoiceDate.toString())
                )}
              </div>
            </div>
          </div>

          <div style={styles.parsedViewSummarySection}>
            <div style={styles.parsedViewSummarySectionColumn}></div>
            {['importer', 'supplier', 'manufacturer'].map((section) => (
              <div
                key={section}
                style={styles.parsedViewSummarySectionColumn}
                onMouseEnter={() => handleMouseEnter(section)}
                onMouseLeave={() => handleMouseLeave(section)}
              >
                <div style={styles.parsedViewSummarySectionColumnHeader}>
                  {section === 'importer'
                    ? 'Importer of Record'
                    : section === 'supplier'
                      ? 'Supplier Information'
                      : 'Manufacturer Information'}
                </div>
                <div>
                  {section === 'importer' ? (
                    <>
                      {commercialInvoice.importerOfRecordName}
                      <br /> {commercialInvoice.importerOfRecordStreet}
                      <br /> {commercialInvoice.importerOfRecordCity}, {commercialInvoice.importerOfRecordState}{' '}
                      {commercialInvoice.importerOfRecordZip}
                      <br />
                      {commercialInvoice.importerOfRecordCountry}
                    </>
                  ) : section === 'supplier' ? (
                    <>
                      {commercialInvoice.supplierName}
                      <br /> {commercialInvoice.supplierStreet}
                      <br /> {commercialInvoice.supplierCity}, {commercialInvoice.supplierState}{' '}
                      {commercialInvoice.supplierZip}
                      <br />
                      {commercialInvoice.supplierCountry}
                    </>
                  ) : (
                    <>
                      {commercialInvoice.manufacturerName}
                      <br /> {commercialInvoice.manufacturerStreet}
                      <br /> {commercialInvoice.manufacturerCity}, {commercialInvoice.manufacturerState}{' '}
                      {commercialInvoice.manufacturerZip}
                      <br />
                      {commercialInvoice.manufacturerCountry}
                    </>
                  )}
                </div>
                {modifyMode[section] && (
                  <IconButton style={styles.editIconButton} onClick={() => handleEditClick(section)} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            ))}
          </div>

          <ManageItems<CommercialInvoiceLineItem>
            manageItemsConfig={manageCommercialInvoiceLineItemsConfig}
            items={commercialInvoice.lineItems}
            getDefaultItem={() => ({
              poNumber: '',
              sku: '',
              description: '',
              materialComposition: '',
              unitQuantity: '',
              unitValue: '',
              currencyCode: '',
              totalValue: '',
              countryOfOrigin: '',
              additionalProperties: {},
              containerNumber: '',
              htsno: '',
              unitWeight: '',
              netWeight: '',
              grossWeight: '',
            })}
            itemName=""
            styles={commercialInvoiceLineItemsStyles}
            useDeleteConfirmationModal={true}
            onItemsUpdated={(items) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, lineItems: items });
            }}
            parentLoadingStatus={loadingStatus}
          />
          <div
            style={{
              fontWeight: 'bold',
              color: 'black',
              fontSize: '12px',
              padding: '6px',
              marginTop: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid lightgrey',
              borderRadius: '4px',
            }}
          >
            <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
            <div>{commercialInvoice.totalValue}</div>
          </div>
        </Box>
      </Card>

      <AddOrEditAProductModal
        open={addOrEditAProductOpen}
        productID={productIDForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        onProductCreatedOrModified={onProductCreatedModifiedOrDeleted}
        onClose={() => setAddOrEditAProductOpen(false)}
        draftStateProductProperties={computedProductPropertiesFromCommercialInvoiceLine}
        businessId={businessId}
      />
    </>
  );
};

export default CommercialInvoiceParsedView;
