import React, { useMemo, useState } from 'react';
import { Checkbox } from '@mui/material';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalVerticalTabs from '@/shared-components/ImportalTabs/ImportalVerticalTabs';
import CommercialInvoiceParsedView from './CommercialInvoiceParsedView';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  CommercialInvoiceWithDocument,
  EntryPrepDocWithDocument,
  PackingList,
} from 'common/interfaces/documentParsing';
import { DocumentType, DocumentTypeToText } from 'common/interfaces/document';
import moment from 'moment';
import PackingListParsedView from '@/broker-app/pages/shipments/PackingListParsedView';
import { useAPI } from '@/api/APIContext';

interface EntryPrepDocumentsViewProps {
  entryPrepDocs: Array<EntryPrepDocWithDocument>;
  onEntryPrepDocUpdated: (arg0: EntryPrepDocWithDocument) => void;
  entryPrepDocsSelections: Array<boolean>;
  setEntryPrepDocsSelections: (arg0: Array<boolean>) => void;
  onNext: () => void;
  onBack: () => void;
}

export default function EntryPrepDocumentsView({
  entryPrepDocs,
  onEntryPrepDocUpdated,
  entryPrepDocsSelections,
  setEntryPrepDocsSelections,
  onNext,
  onBack,
}: EntryPrepDocumentsViewProps) {
  const api = useAPI();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const renderViewBasedOnDocumentType = (document: EntryPrepDocWithDocument) => {
    switch (document.documentType) {
      case DocumentType.COMMERCIAL_INVOICE_AND_PACKING_LIST:
      case DocumentType.COMMERCIAL_INVOICE:
        return (
          <CommercialInvoiceParsedView
            commercialInvoice={document.properties as CommercialInvoiceWithDocument}
            onCommercialInvoiceChanged={(commercialInvoice) => {
              onEntryPrepDocUpdated({ ...document, properties: commercialInvoice });
            }}
          />
        );
      case DocumentType.PACKING_LIST:
        return (
          <PackingListParsedView
            packingList={document.properties as PackingList}
            onPackingListChanged={(packingList) => {
              onEntryPrepDocUpdated({ ...document, properties: packingList });
            }}
          />
        );

      default:
        return <div>Document type not supported.</div>;
    }
  };

  const tabsConfig = useMemo(() => {
    return entryPrepDocs.map((entryPrepDoc, index) => ({
      title: entryPrepDoc.documentType,
      label: (
        <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => setSelectedTabIndex(index)}>
          <div
            style={{
              textAlign: 'left',
              width: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <div style={{ fontSize: '11px' }}>
              <b>{entryPrepDoc.document.fileName}</b>
            </div>
            <div style={{ fontSize: '10px' }}>
              {DocumentTypeToText[entryPrepDoc.document.type]} (
              {moment((entryPrepDoc.document as any).createdAt)
                .tz('America/Chicago')
                .format('MM/DD/YY')}
              )
            </div>
            <div style={{ fontSize: '10px' }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`/documents/${entryPrepDoc.document._id}`, '_blank', 'width=800,height=600');
                }}
              >
                View Document
              </a>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '5px' }}>
            <Checkbox
              size="small"
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '16px', // Adjust the icon size to make it smaller
                },
                padding: '2px', // Reduce padding around the checkbox
              }}
              checked={entryPrepDocsSelections[index] || false}
              onChange={(e) => {
                e.preventDefault(); // Prevent the onClick from the label
                const newSelections = [...entryPrepDocsSelections];
                newSelections[index] = e.target.checked;
                setEntryPrepDocsSelections(newSelections);
              }}
            />
          </div>
        </div>
      ),
      element: renderViewBasedOnDocumentType(entryPrepDoc),
    }));
  }, [entryPrepDocs, entryPrepDocsSelections, setEntryPrepDocsSelections, onEntryPrepDocUpdated]);

  return (
    <>
      <ImportalVerticalTabs
        tabsConfig={tabsConfig}
        inCard={true}
        selectedTabIndex={selectedTabIndex}
        // onTabChange={handleTabChange}
      />
      <div className="next-button-container">
        <ImportalPrimaryButton endIcon={<KeyboardArrowRightIcon />} onClick={onNext} text="Next" />
      </div>
    </>
  );
}
