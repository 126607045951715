import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { EntryPrepDocWithDocument } from 'common/interfaces/documentParsing';
import { DocumentType } from 'common/interfaces/document';
import { useAPI } from '@/api/APIContext';
import ImportalCard from '@/shared-components/ImportalCard';
import EntryPrepDocumentsView from './EntryPrepDocumentsView';
import { ImportalStepper } from '@/shared-components/ImportalStepper/ImportalStepper';
import EntryPrepApplicationFiling from './EntryPrepApplicationFiling';
import { EntryInvoice } from 'common/interfaces/entry';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

export interface IEntryPrepTabContext {
  loadingStatus: LoadingStatus;
  setLoadingStatus: (arg0: LoadingStatus) => void;
}

export const EntryPrepTabContext = createContext<IEntryPrepTabContext>({
  loadingStatus: LoadingStatus.NOT_LOADING,
  setLoadingStatus: () => {},
});

const { Provider } = EntryPrepTabContext;

interface Props {
  shipmentId: string;
}

export default function EntryPrepTab({ shipmentId }: Props) {
  const { businessId } = useContext(ShipmentPageContext);
  const api = useAPI();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);

  const [entryPrepDocs, setEntryPrepDocs] = useState<Array<EntryPrepDocWithDocument>>([]);

  const [entryPrepDocSelections, setEntryPrepDocSelections] = useState<Array<boolean>>([]);

  const [entryInvoice, setEntryInvoice] = useState<EntryInvoice | undefined>();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<Record<number, boolean>>({});

  useEffect(() => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getEntryPrepDocsForShipment(shipmentId)
      .then(({ data }) => {
        setEntryPrepDocs(data);
        setEntryPrepDocSelections(data?.map((_) => false));
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        console.error('Error getting entry prep docs for shipment', err);
        setLoadingStatus(LoadingStatus.ERROR);
      });
  }, [shipmentId, api]);

  const onEntryPrepDocUpdated = async (updatedDocument: EntryPrepDocWithDocument): Promise<any> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.updateEntryPrepDoc(updatedDocument._id.toString(), updatedDocument);
      const index = entryPrepDocs.findIndex(
        (entryPrepDoc) => updatedDocument._id.toString() === entryPrepDoc._id.toString()
      );
      const updatedEntryPrepDocs = [...entryPrepDocs];
      updatedEntryPrepDocs[index] = data;
      setEntryPrepDocs(updatedEntryPrepDocs);
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('error updating entry prep doc');
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const createEntryInvoiceFromSelectedEntryPrepDocs = useCallback(async () => {
    // Extract selected documents
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );

    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.convertToEntryInvoice(selectedEntryPrepDocs, businessId);
      setEntryInvoice(data);
      handleComplete(); // Proceed to the next step upon success
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('Error creating entry invoice from prep docs', err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  }, [api, entryPrepDocs, entryPrepDocSelections]);

  const validEntryPrepDocsAreSelected = (): boolean => {
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );
    return selectedEntryPrepDocs.some((doc) =>
      [DocumentType.COMMERCIAL_INVOICE_AND_PACKING_LIST, DocumentType.COMMERCIAL_INVOICE].includes(doc.documentType)
    );
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const getElements = () => steps.map((step) => step.element);

  const steps = [
    {
      label: 'Prepare Documents',
      element: (
        <EntryPrepDocumentsView
          entryPrepDocs={entryPrepDocs}
          onEntryPrepDocUpdated={onEntryPrepDocUpdated}
          entryPrepDocsSelections={entryPrepDocSelections}
          setEntryPrepDocsSelections={setEntryPrepDocSelections}
          onNext={createEntryInvoiceFromSelectedEntryPrepDocs}
          onBack={handleBack}
        />
      ),
    },
    {
      label: 'File Entry Application',
      element: <EntryPrepApplicationFiling entryInvoice={entryInvoice} onNext={handleComplete} onBack={handleBack} />,
    },
  ];

  return (
    <ImportalCard title="Entry Prep">
      <Provider
        value={{
          loadingStatus,
          setLoadingStatus,
        }}
      >
        <ImportalStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} completed={completed} />
        {getElements()[activeStep]}
      </Provider>
    </ImportalCard>
  );
}
