import * as React from 'react';
import moment, { Moment } from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface ImportalDatePickerProps {
  label: string;
  date: Date | null; // The input and output will be JavaScript Date objects
  setDate: (newDate: Date | null) => void; // Function to update the date
}

export default function ImportalDatePicker({ label, date, setDate }: ImportalDatePickerProps) {
  const handleDateChange = (newValue: Moment | null) => {
    setDate(newValue ? newValue.toDate() : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={label}
          value={date ? moment(date).utcOffset(-4).startOf('day') : null} // Use EDT offset and start of day
          onChange={handleDateChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
